<template>
  <v-row v-if="property && property.Id > 0">
    <v-col cols="1">
      <v-row>
        <BaseTooltipButton
          v-if="canEdit"
          small
          @click="$refs.taxablePropertyForm.editTaxableProperty(property)"
          iconColor="primary"
          icon="mdi-pencil"
          >Edit Taxable Property
        </BaseTooltipButton>
      </v-row>
      <v-row v-if="!propertyId">
        <BaseTooltipButton
          small
          @click="
            $router.push({
              name: 'staffForm',
              params: { appId: null },
              query: { propertyId: property.Id },
            })
          "
          iconColor="primary"
          icon="mdi-file-document-multiple"
          >View Property Applications
        </BaseTooltipButton>
      </v-row>
    </v-col>
    <v-col>
      <div> Parcel: {{ property.ParcelId }} </div>
      <div>County: {{ county }}</div>
      <div v-if="expand">
        <div>
          Property Address:
        </div>
        <div>
          {{ property.StreetAddress1 }}
        </div>
        <div v-if="property.StreetAddress2">
          {{ property.StreetAddress2 }}
        </div>
        <div>
          {{ cityStateZip }}
        </div>
      </div>
    </v-col>
    <TaxablePropertyDialogForm
      ref="taxablePropertyForm"
      @refresh="$emit('refresh')"
      :contact="property.Contact"
    >
    </TaxablePropertyDialogForm>
  </v-row>
</template>
<script>
import TaxablePropertyDialogForm from '@components/form/staff/taxablePropertyDialog'
export default {
  //model: {
  //	prop: ''
  //},
  props: {
    property: {
      type: Object,
    },
    expand: {
      type: Boolean,
    },
    canEdit: {
      type: Boolean,
    },
  },
  components: {
    TaxablePropertyDialogForm,
  },
  data() {
    return {}
  },
  computed: {
    cityStateZip() {
      var city = this.property.City ? this.property.City.Name : ''
      var state = this.property.State ? this.property.State.Abbr : ''
      var zip = this.property.Zip ? this.property.Zip : ''
      return `${city}, ${state} ${zip}`
    },
    county() {
      return this.property.County ? this.property.County.Name : ''
    },
    propertyId() {
      return parseInt(this.$route.query.propertyId) || false
    },
  },
  methods: {},
  watch: {},
}
</script>
<style scoped></style>
