<template>
  <BasePanelWithHeader :collapsable="true" headerText="Notes">
    <template v-slot:header>
      Notes<BaseTooltipButton
        icon="mdi-plus-circle"
        iconColor="white"
        @click.stop="addNote"
      >
        Add Note
      </BaseTooltipButton>
    </template>
    <BaseSimpleDataTable
      item-key="Id"
      :headers="filteredHeaders"
      :items="mappedApplicationNotes"
      :searchText="searchText"
      :loading="applicationNotesLoading"
      sortBy="UpdatedDate"
      sortDesc
      :expanded.sync="expanded"
      single-expand
      show-expand
    >
      <template v-slot:searchParams>
        <v-row>
          <v-col class="ml-auto" cols="12" sm="auto">
            <v-text-field
              v-model="searchText"
              append-icon="mdi-magnify"
              label="Search"
              clearable
              single-line
              hide-details
            >
            </v-text-field>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.edit="{ item }">
        <BaseTooltipButton
          small
          @click="$refs.applicationNoteForm.editApplicationNote(item)"
          iconColor="primary"
          icon="mdi-pencil"
          >Edit Application Note
        </BaseTooltipButton>
      </template>
      <template v-slot:item.delete="{ item }">
        <BaseTooltipButton
          small
          @click="$refs.applicationNoteDelete.deleteConfirm(item)"
          iconColor="primary"
          icon="mdi-delete"
          >Delete Application Note
        </BaseTooltipButton>
      </template>
      <template v-slot:item.Note="{ item }">
        <span @click="expandRow(item)">
          {{ item.Note | snippet(50) }}
        </span>
      </template>
      <template v-slot:item.CreatedBy="{ item }"
        >{{ item.CreatedBy.FirstName }} {{ item.CreatedBy.LastName }}</template
      >
      <template v-slot:item.CreatedDate="{ item }">{{
        item.CreatedDate | formatDateTime
      }}</template>
      <template v-slot:item.UpdatedBy="{ item }"
        >{{ item.UpdatedBy.FirstName }} {{ item.UpdatedBy.LastName }}</template
      >
      <template v-slot:item.UpdatedDate="{ item }">{{
        item.UpdatedDate | formatDateTime
      }}</template>
      <template v-slot:expanded-item="{ item, headers }">
        <td :colspan="headers.length" class="pa-2">
          {{ item.Note }}
        </td>
      </template>
    </BaseSimpleDataTable>
    <BaseDeleteConfirm
      ref="applicationNoteDelete"
      :delete="deleteApplicationNote"
      @refresh="loadSelectedApplicationApplicationNotes"
    >
      Are you sure you want to delete this application note?
    </BaseDeleteConfirm>
    <ApplicationNoteDialogForm
      ref="applicationNoteForm"
      @refresh="loadSelectedApplicationApplicationNotes"
    >
    </ApplicationNoteDialogForm>
  </BasePanelWithHeader>
</template>
<script>
import { get, sync, commit, call } from 'vuex-pathify'
import { authMethods, authComputed } from '@state/helpers'
import ApplicationNoteDialogForm from '@components/form/add-edit/ApplicationNoteDialog'
export default {
  //model: {
  //	prop: ''
  //},
  props: {},
  components: {
    ApplicationNoteDialogForm,
  },
  data() {
    return {
      searchText: '',
      expanded: [],
    }
  },
  computed: {
    ...authComputed,
    ...get('application', [
      'selectedApplication',
      'selectedApplicationApplicationNotes',
      'selectedApplicationApplicationNotesId',
      'applicationNotesLoading',
    ]),
    mappedApplicationNotes() {
      var list = this.selectedApplicationApplicationNotes.map((i) => {
        return {
          ...i,
          noteType: i.NoteType.Description,
        }
      })
      return list
    },
    headers() {
      return [
        {
          text: '',
          align: 'center',
          value: 'edit',
          visible: true,
          sortable: false,
        },
        {
          text: 'Id',
          align: 'center',
          sortable: true,
          value: 'Id',
          visible: false,
        },
        {
          text: 'Application Id',
          align: 'center',
          sortable: true,
          value: 'ApplicationId',
          visible: false,
        },
        {
          text: 'Note',
          align: 'center',
          sortable: true,
          value: 'Note',
          visible: true,
        },
        {
          text: 'Note Type',
          align: 'center',
          sortable: true,
          value: 'noteType',
          visible: true,
        },
        {
          text: 'Created By',
          align: 'center',
          sortable: true,
          value: 'CreatedBy',
          visible: true,
        },
        {
          text: 'Created Date',
          align: 'center',
          sortable: true,
          value: 'CreatedDate',
          visible: true,
        },
        {
          text: 'Updated By',
          align: 'center',
          sortable: true,
          value: 'UpdatedBy',
          visible: true,
        },
        {
          text: 'Updated Date',
          align: 'center',
          sortable: true,
          value: 'UpdatedDate',
          visible: true,
        },
        {
          text: '',
          align: 'center',
          value: 'delete',
          visible: false,
          sortable: false,
        },
      ]
    },
    filteredHeaders() {
      return this.headers.filter((h) => h.visible)
    },
  },
  methods: {
    ...call('application', ['loadSelectedApplicationApplicationNotes']),
    ...call('applicationNote', ['deleteApplicationNote']),
    addNote() {
      this.$refs.applicationNoteForm.addApplicationNote(
        this.selectedApplicationApplicationNotesId
      )
    },
    expandRow(item) {
      this.expanded = item === this.expanded[0] ? [] : [item]
    },
  },
  watch: {},
}
</script>
<style scoped></style>
