<template>
  <v-form ref="form" @submit.prevent lazy-validation v-model="valid">
    <v-card class="elevation-3">
      <v-card-title class="primary white--text text-h5">
        {{ headerText }}
      </v-card-title>
      <v-card-text class="pa-4">
        <!-- <v-row>
          <v-col cols="12">
            <v-text-field
              label="Application Number"
              v-model="selectedApplication.ApplicationNumber"
            ></v-text-field>
          </v-col>
        </v-row> -->
        <v-row>
          <v-col cols="12" sm="6" md="3" lg="3">
            <BaseDatePickerWithText
              clearable
              label="Postmark Date mm/dd/yyyy"
              v-model="selectedApplication.PostmarkDate"
            ></BaseDatePickerWithText>
          </v-col>
          <!-- </v-row>
        <v-row> -->
          <v-col cols="12" sm="6" md="3" lg="3">
            <BaseCurrencyField
              clearable
              label="Tax Bill Amount"
              v-model="selectedApplication.TaxBillAmount"
            ></BaseCurrencyField>
          </v-col>
          <v-col cols="12" sm="6" md="3" lg="3">
            <BaseCurrencyField
              clearable
              label="Total Household Income Reported"
              :allowNegative="true"
              v-model="selectedApplication.TotalHouseholdIncomeReported"
            ></BaseCurrencyField>
          </v-col>
          <!-- </v-row>
        <v-row> -->
          <v-col cols="12" sm="6" md="3" lg="3">
            <BaseCurrencyField
              clearable
              label="Business Deduction"
              v-model="selectedApplication.BusinessDeduction"
            ></BaseCurrencyField>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" md="3" lg="3">
            <BaseCurrencyField
              clearable
              label="Payment 1 Amount"
              v-model="selectedApplication.Payment1Amount"
            ></BaseCurrencyField>
          </v-col>
          <!-- </v-row>
        <v-row> -->
          <v-col cols="12" sm="6" md="3" lg="3">
            <BaseDatePickerWithText
              clearable
              label="Payment 1 Date mm/dd/yyyy"
              v-model="selectedApplication.Payment1Date"
            ></BaseDatePickerWithText>
          </v-col>
          <!-- </v-row>
        <v-row> -->
          <v-col cols="12" sm="6" md="3" lg="3">
            <BaseCurrencyField
              clearable
              label="Payment 2 Amount"
              v-model="selectedApplication.Payment2Amount"
            ></BaseCurrencyField>
          </v-col>
          <!-- </v-row>
        <v-row> -->
          <v-col cols="12" sm="6" md="3" lg="3">
            <BaseDatePickerWithText
              clearable
              label="Payment 2 Date mm/dd/yyyy"
              v-model="selectedApplication.Payment2Date"
            ></BaseDatePickerWithText>
          </v-col>
        </v-row>
        <v-row> </v-row>
        <v-row>
          <v-col cols="12" sm="3">
            <v-checkbox
              label="Is Primary Residence"
              v-model="selectedApplication.IsPrimaryResidence"
            ></v-checkbox>
          </v-col>
          <!-- </v-row>
        <v-row> -->
          <v-col cols="12" sm="3">
            <v-checkbox
              label="Is Tax Bill Attached"
              v-model="selectedApplication.IsTaxBillAttached"
            ></v-checkbox>
          </v-col>
          <!-- </v-row>
        <v-row> -->
          <v-col cols="12" sm="3">
            <v-checkbox
              label="Is Payment Receipt Attached"
              v-model="selectedApplication.IsPaymentReceiptAttached"
            ></v-checkbox>
          </v-col>
          <!-- </v-row>
        <v-row> -->
          <v-col cols="12" sm="3">
            <v-checkbox
              label="Are Household Details Provided"
              v-model="selectedApplication.AreHouseholdDetailsProvided"
            ></v-checkbox>
          </v-col>
          <!-- </v-row>
        <v-row> -->
          <v-col cols="12" sm="3">
            <v-checkbox
              label="Do Residential Requirements Qualify"
              v-model="selectedApplication.DoResidentialRequirementsQualify"
            ></v-checkbox>
          </v-col>
          <!-- </v-row>
        <v-row> -->
          <v-col cols="12" sm="3">
            <v-checkbox
              label="Do Household Assets Qualify"
              v-model="selectedApplication.DoHouseholdAssetsQualify"
            ></v-checkbox>
          </v-col>
          <!-- </v-row>
        <v-row> -->
          <v-col cols="12" sm="3">
            <v-checkbox
              label="Does Tax Bill exceed 10% of Income"
              v-model="selectedApplication.PropertyTaxGreaterThanIncomePercentage"
            ></v-checkbox>
          </v-col>
          <v-col cols="12" sm="3">
            <v-checkbox
              label="Is Irs Attached"
              v-model="selectedApplication.IsIrsAttached"
            ></v-checkbox>
          </v-col>
          <!-- </v-row>
        <v-row> -->
          <v-col cols="12" sm="3">
            <v-checkbox
              label="Is Application Signed"
              v-model="selectedApplication.IsApplicationSigned"
            ></v-checkbox>
          </v-col>
        </v-row>
        <!-- //todo: will set this automatically or at a later date -->
        <v-row v-if="inDialog">
          <v-col cols="12" sm="6" md="3">
            <BaseCurrencyField
              label="Refund Amount"
              v-model="selectedApplication.RefundAmount"
              disabled
            ></BaseCurrencyField>
          </v-col>
          <!-- </v-row>
        <v-row v-i="inDialog"> -->
          <v-col cols="12" sm="6" md="3">
            <BaseDatePickerWithText
              label="Refund Status Date mm/dd/yyyy"
              v-model="selectedApplication.RefundStatusDate"
              disabled
            ></BaseDatePickerWithText>
          </v-col>
        </v-row>
        <!-- <v-row>
          <v-col cols="12">
            <v-checkbox
              label="Certify Ssn"
              v-model="selectedApplication.CertifySsn"
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-checkbox
              label="No Backup Withholding"
              v-model="selectedApplication.NoBackupWithholding"
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-checkbox
              label="Us Resident"
              v-model="selectedApplication.UsResident"
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-checkbox
              label="Correct And Complete"
              v-model="selectedApplication.CorrectAndComplete"
            ></v-checkbox>
          </v-col>
        </v-row> -->
        <!-- <v-row>
            <v-col cols="12">
              <LuPeriod
                label="Period"
                v-model="selectedApplication.PeriodId"
                  :rules="[v => !!v || 'required']"
              ></LuPeriod>
            </v-col>
          </v-row> -->
        <!-- <v-row>
            <v-col cols="12">
              <TaxableProperty
                label="Taxable Property"
                v-model="selectedApplication.TaxablePropertyId"
                  :rules="[v => !!v || 'required']"
              ></TaxableProperty>
            </v-col>
          </v-row> -->
        <!-- HouseholdMembers Join Items-->
        <!-- <v-row>
            <v-col cols="12">
              <ApplicationDocument
                label="Application Documents"
                v-model="selectedApplication.HouseholdMembers"
                selfKey="ApplicationId"
                :selfId="selectedApplication.Id"
                relatedItemKey="HouseholdMemberId"
                joinItemName="HouseholdMember"
              ></ApplicationDocument>
            </v-col>
          </v-row> -->
      </v-card-text>
      <v-card-actions>
        <v-btn
          @click="saveApplicationDetails"
          :disabled="saving"
          color="primary"
          >Save</v-btn
        >
        <v-btn v-if="inDialog" outlined color="primary" @click="cancelEntry"
          >Cancel</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-form>
</template>
<script>
import { get, sync, commit, call } from 'vuex-pathify'
import Application from '@classes/Application'
export default {
  //model: {
  //	prop: ''
  //},
  props: {
    dialog: {
      type: Boolean,
      default: null,
    },
    property: {
      type: Object,
    },
  },
  created() {
    if (!this.inDialog) {
      this.addApplication(this.property.Id)
    }
  },
  components: {},
  data() {
    return {
      valid: true,
      selectedApplication: new Application(),
      headerText: 'New Application',
    }
  },
  computed: {
    ...get('application', ['saving']),
    ...get('luPeriod', ['currentLuPeriod']),
    inDialog() {
      if (this.dialog === false || this.dialog === true) {
        return true
      } else {
        return false
      }
    },
  },
  methods: {
    ...call('application', ['saveApplication']),
    async validate() {
      await this.$refs.form.validate()
    },
    editApplication(entry) {
      this.selectedApplication = new Application(entry)
      this.headerText = 'Edit Application'
    },
    addApplication(propertyId) {
      this.headerText = 'Insert Application'
      if (propertyId) {
        this.selectedApplication = new Application({
          TaxablePropertyId: propertyId,
          PeriodId: this.currentLuPeriod.Id,
        })
      } else {
        this.selectedApplication = new Application()
      }
    },
    async saveApplicationDetails() {
      await this.validate()
      console.devlog({ valid: this.valid })
      if (this.valid) {
        try {
          let app = this.selectedApplication.root()
          if (app.TaxablePropertyId == 0) {
            app.TaxablePropertyId = this.property.Id
          }
          if (app.PeriodId == 0) {
            app.PeriodId = this.currentLuPeriod.Id
          }
          let res = await this.saveApplication(app)
          this.$emit('refresh')
          this.$emit('itemAdded', res.data)
          this.selectedApplication = new Application()
          this.$refs.form.resetValidation()
        } catch (err) {}
        this.$emit('update:dialog', false)
      }
    },
    cancelEntry() {
      this.selectedApplication = new Application()
      this.$refs.form.resetValidation()
      this.$emit('update:dialog', false)
    },
  },
  watch: {},
}
</script>
<style scoped></style>
