<template>
  <BasePanelWithHeader
    collapsable
    :initialCollapse="!applicationsForCurrentPeriod.length > 0"
    headerText="Existing Applications"
  >
    <BaseSimpleDataTable
      :headers="filteredHeaders"
      :items="mappedApplications"
      :search="false"
      sortBy="period"
      sortDesc
    >
      <template v-slot:searchParams>
        <!-- <v-row>
          <v-col class="ml-auto" cols="12" sm="auto">
            <v-text-field
              v-model="searchText"
              append-icon="mdi-magnify"
              label="Search"
              clearable
              single-line
              hide-details
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="auto" class="mt-4 mr-4">
            <BaseTooltipButton
              small
              @click="$refs.applicationForm.addApplication()"
              icon="mdi-plus"
              iconColor="white"
              buttonClass="primary"
              >Add Application
            </BaseTooltipButton>
          </v-col>
        </v-row> -->
      </template>
      <template v-slot:item.period="{ item }">
        <span
          :class="
            item.PeriodId == currentLuPeriod.Id
              ? 'font-weight-black green--text text--darken-2'
              : ''
          "
        >
          {{ item.period }}
        </span>
      </template>
      <template v-slot:item.edit="{ item }">
        <BaseTooltipButton
          small
          iconColor="primary"
          icon="mdi-check-circle"
          @click="
            $router.push({ name: 'staffForm', params: { appId: item.Id } })
          "
          >Select Application
        </BaseTooltipButton>
      </template>
      <template v-slot:item.PostmarkDate="{ item }">{{
        item.PostmarkDate | formatDate
      }}</template>
      <template v-slot:item.TaxBillAmount="{ item }">{{
        item.TaxBillAmount | formatMoney
      }}</template>
      <template v-slot:item.BusinessDeduction="{ item }">{{
        item.BusinessDeduction | formatMoney
      }}</template>
      <template v-slot:item.Payment1Amount="{ item }">{{
        item.Payment1Amount | formatMoney
      }}</template>
      <template v-slot:item.Payment1Date="{ item }">{{
        item.Payment1Date | formatDate
      }}</template>
      <template v-slot:item.Payment2Amount="{ item }">{{
        item.Payment2Amount | formatMoney
      }}</template>
      <template v-slot:item.Payment2Date="{ item }">{{
        item.Payment2Date | formatDate
      }}</template>
      <template v-slot:item.TotalHouseholdIncomeReported="{ item }">{{
        item.TotalHouseholdIncomeReported | formatMoney
      }}</template>
      <template v-slot:item.RefundAmount="{ item }">{{
        item.RefundAmount | formatMoney
      }}</template>
      <template v-slot:item.RefundStatusDate="{ item }">{{
        item.RefundStatusDate | formatDate
      }}</template>
      <template v-slot:item.UpdatedDate="{ item }">{{
        item.UpdatedDate | formatDate
      }}</template>
    </BaseSimpleDataTable>
  </BasePanelWithHeader>
</template>
<script>
import { get, sync, commit, call } from 'vuex-pathify'
export default {
  //model: {
  //	prop: ''
  //},
  props: {
    applications: {
      type: Array,
    },
  },
  components: {},
  data() {
    return {}
  },
  computed: {
    ...get('luPeriod', ['currentLuPeriod']),
    mappedApplications() {
      return this.applications.map((item) => {
        return {
          ...item,
          period: item.Period ? item.Period.Period : '',
          currentStatus: item.ApplicationStatusHistories.reduce((m, v, i) =>
            new Date(v.CreatedDate).getTime() >
              new Date(m.CreatedDate).getTime() && i
              ? v
              : m
          )?.Status?.StatusCode,
        }
      })
    },
    headers() {
      return [
        {
          text: '',
          align: 'center',
          value: 'edit',
          visible: true,
          sortable: false,
        },
        {
          text: 'Id',
          align: 'center',
          sortable: true,
          value: 'Id',
          visible: false,
        },
        {
          text: 'Application Number',
          align: 'center',
          sortable: true,
          value: 'ApplicationNumber',
          visible: true,
        },
        {
          text: 'Period',
          align: 'center',
          sortable: true,
          value: 'period',
          visible: true,
        },
        {
          text: 'Status',
          align: 'center',
          sortable: true,
          value: 'currentStatus',
          visible: true,
        },
        {
          text: 'Postmark Date',
          align: 'center',
          sortable: true,
          value: 'PostmarkDate',
          visible: true,
        },
        {
          text: 'Period Id',
          align: 'center',
          sortable: true,
          value: 'PeriodId',
          visible: false,
        },
        {
          text: 'Taxable Property Id',
          align: 'center',
          sortable: true,
          value: 'TaxablePropertyId',
          visible: false,
        },
        {
          text: 'Tax Bill Amount',
          align: 'center',
          sortable: true,
          value: 'TaxBillAmount',
          visible: true,
        },
        {
          text: 'Business Deduction',
          align: 'center',
          sortable: true,
          value: 'BusinessDeduction',
          visible: true,
        },
        {
          text: 'Payment 1 Amount',
          align: 'center',
          sortable: true,
          value: 'Payment1Amount',
          visible: true,
        },
        {
          text: 'Payment 1 Date',
          align: 'center',
          sortable: true,
          value: 'Payment1Date',
          visible: true,
        },
        {
          text: 'Payment 2 Amount',
          align: 'center',
          sortable: true,
          value: 'Payment2Amount',
          visible: true,
        },
        {
          text: 'Payment 2 Date',
          align: 'center',
          sortable: true,
          value: 'Payment2Date',
          visible: true,
        },
        {
          text: 'Total Household Income Reported',
          align: 'center',
          sortable: true,
          value: 'TotalHouseholdIncomeReported',
          visible: true,
        },
        {
          text: 'Is Primary Residence',
          align: 'center',
          sortable: true,
          value: 'IsPrimaryResidence',
          visible: false,
        },
        {
          text: 'Is Tax Bill Attached',
          align: 'center',
          sortable: true,
          value: 'IsTaxBillAttached',
          visible: false,
        },
        {
          text: 'Is Payment Receipt Attached',
          align: 'center',
          sortable: true,
          value: 'IsPaymentReceiptAttached',
          visible: false,
        },
        {
          text: 'Do Household Assets Qualify',
          align: 'center',
          sortable: true,
          value: 'DoHouseholdAssetsQualify',
          visible: false,
        },
        {
          text: 'Is Application Signed',
          align: 'center',
          sortable: true,
          value: 'IsApplicationSigned',
          visible: false,
        },
        {
          text: 'Refund Amount',
          align: 'center',
          sortable: true,
          value: 'RefundAmount',
          visible: true,
        },
        {
          text: 'Refund Status Date',
          align: 'center',
          sortable: true,
          value: 'RefundStatusDate',
          visible: true,
        },
        {
          text: 'Created By Id',
          align: 'center',
          sortable: true,
          value: 'CreatedById',
          visible: false,
        },
        {
          text: 'Created Date',
          align: 'center',
          sortable: true,
          value: 'CreatedDate',
          visible: false,
        },
        {
          text: 'Updated By Id',
          align: 'center',
          sortable: true,
          value: 'UpdatedById',
          visible: false,
        },
        {
          text: 'Updated Date',
          align: 'center',
          sortable: true,
          value: 'UpdatedDate',
          visible: true,
        },
        {
          text: 'Certify Ssn',
          align: 'center',
          sortable: true,
          value: 'CertifySsn',
          visible: false,
        },
        {
          text: 'No Backup Withholding',
          align: 'center',
          sortable: true,
          value: 'NoBackupWithholding',
          visible: false,
        },
        {
          text: 'Us Resident',
          align: 'center',
          sortable: true,
          value: 'UsResident',
          visible: false,
        },
        {
          text: 'Correct And Complete',
          align: 'center',
          sortable: true,
          value: 'CorrectAndComplete',
          visible: false,
        },
        {
          text: '',
          align: 'center',
          value: 'delete',
          visible: false,
          sortable: false,
        },
      ]
    },
    filteredHeaders() {
      return this.headers.filter((h) => h.visible)
    },
    applicationsForCurrentPeriod() {
      if (
        this.currentLuPeriod &&
        this.currentLuPeriod.Id > 0 &&
        this.applications &&
        this.applications.length > 0
      ) {
        var apps = this.applications.filter(
          (x) => x.PeriodId == this.currentLuPeriod.Id
        )
        return apps
      } else {
        return []
      }
    },
  },
  methods: {},
  watch: {},
}
</script>
<style scoped></style>
