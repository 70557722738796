<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <div height="100%">
          <BaseStickyHeader
            v-if="contact && contact.Id > 0 && !loading"
            class="pt-2 pl-2"
          >
            <v-row class="pt-2 pl-2">
              <v-col cols="6">
                <ContactHeader
                  :canEdit="canEdit"
                  :contact="contact"
                  @refresh="refreshContact"
                  :expand="$vuetify.breakpoint.mdAndUp || expandHeader"
                ></ContactHeader>
              </v-col>
              <v-col cols="6">
                <PropertyHeader
                  v-if="property"
                  :property="property"
                  :canEdit="canEdit"
                  @refresh="refreshProperty"
                  :expand="$vuetify.breakpoint.mdAndUp || expandHeader"
                ></PropertyHeader>
              </v-col>
            </v-row>
            <v-row no-gutters v-if="$vuetify.breakpoint.smAndDown">
              <v-col cols="12" align="center">
                <BaseTooltipButton
                  small
                  @click="expandHeader = !expandHeader"
                  iconColor="primary"
                  :icon="expandHeader ? 'mdi-chevron-up' : 'mdi-chevron-down'"
                  >Expand
                </BaseTooltipButton>
              </v-col>
            </v-row>
          </BaseStickyHeader>
          <v-container fluid class="pa-0">
            <div>
              <div v-if="!loading && !haveContact">
                <v-row>
                  <v-col cols="12">
                    <h1 class="text-h4 primary--text">Contact</h1>
                    <h2 class="text-subtitle-1 primary--text pb-6"
                      >Please enter the following information</h2
                    >
                  </v-col>
                </v-row>
                <ContactEntryForm
                  :canEdit="canEdit"
                  @itemAdded="$router.push({ query: { contactId: $event.Id } })"
                ></ContactEntryForm>
              </div>
              <div v-if="!loading && haveContact && !haveProperty">
                <PropertyGrid
                  v-if="contactProperties.length > 0"
                  :contact="contact"
                  :properties="contactProperties"
                ></PropertyGrid>
                <PropertyEntryForm
                  v-if="contactProperties.length == 0"
                  :canEdit="canEdit"
                  :contact="contact"
                  @itemAdded="
                    $router.push({ query: { propertyId: $event.Id } })
                  "
                ></PropertyEntryForm>
              </div>
              <div
                v-if="
                  !loading && haveContact && haveProperty && !haveApplication
                "
              >
                <ApplicationGrid
                  v-if="property.Applications.length > 0"
                  :applications="property.Applications"
                >
                </ApplicationGrid>
                <div
                  v-if="
                    propertyApplicationsForCurrentPeriod &&
                    propertyApplicationsForCurrentPeriod.length > 0
                  "
                  ><v-alert color="secondary white--text"
                    >An application exists for the current period</v-alert
                  ></div
                >
                <ApplicationForm
                  v-if="propertyApplicationsForCurrentPeriod.length == 0"
                  :property="property"
                  :canEdit="canEdit"
                  @itemAdded="
                    $router.push({
                      name: 'staffForm',
                      params: { appId: $event.Id },
                    })
                  "
                ></ApplicationForm>
              </div>
              <div v-if="!loading && haveApplication">
                <ApplicationDetails
                  :application="selectedApplication"
                  :canEdit="canEdit"
                ></ApplicationDetails>
              </div>
            </div>
          </v-container>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { get, sync, commit, call } from 'vuex-pathify'
import dayjs from 'dayjs'
import ContactHeader from '@components/form/staff/contactHeader'
import PropertyHeader from '@components/form/staff/propertyHeader.vue'
import ContactEntryForm from '@components/form/staff/contactEntryForm.vue'
import PropertyEntryForm from '@components/form/staff/propertyEntryForm.vue'
import PropertyGrid from '@components/form/staff/propertyGrid.vue'
import ApplicationDetails from '@components/form/staff/applicationDetails.vue'
import ApplicationForm from '@components/form/staff/applicationForm.vue'
import ApplicationGrid from '@components/form/staff/applicationGrid.vue'
export default {
  //model: {
  //	prop: ''
  //},
  page() {
    let title = 'Form'
    if (this.selectedApplication && this.selectedApplication.Id > 0) {
      title = 'Application: ' + this.selectedApplication.ApplicationNumber
    } else if (this.property) {
      title = `Property: ${this.property.ParcelId}`
    } else if (this.contact) {
      title = `Contact: ${this.contact.FirstName} ${this.contact.LastName}`
    }
    return { title }
  },
  props: {},
  components: {
    ContactHeader,
    PropertyHeader,
    PropertyGrid,
    ContactEntryForm,
    PropertyEntryForm,
    ApplicationForm,
    ApplicationGrid,
    ApplicationDetails,
  },
  data() {
    return {
      expandHeader: false,
    }
  },
  computed: {
    ...get('luPeriod', ['currentLuPeriod']),
    luPeriodLoading: get('luPeriod/loading'),
    ...get('application', ['selectedApplication']),
    applicationLoading: get('application/loading'),
    ...get('taxableProperty', ['selectedTaxableProperty']),
    taxablePropertyLoading: get('taxableProperty/loading'),
    ...get('contact', ['selectedContact']),
    contactLoading: get('contact/loading'),
    appId() {
      return parseInt(this.$route.params.appId) || false
    },
    contactId() {
      return parseInt(this.$route.query.contactId) || false
    },
    propertyId() {
      return parseInt(this.$route.query.propertyId) || false
    },
    canEdit() {
      let period = this.selectedApplication?.Period || this.currentLuPeriod
      let withinPeriod =
        dayjs(period.ApplicationOpenDate) <= dayjs() &&
        dayjs(period.PeriodEndDate) >= dayjs()
      if (this.selectedApplication?.ApplicationStatusHistories?.length > 0) {
        var currentStatus =
          this.selectedApplication.ApplicationStatusHistories.reduce(
            (m, v, i) =>
              new Date(v.CreatedDate).getTime() >
                new Date(m.CreatedDate).getTime() && i
                ? v
                : m
          )
        if (currentStatus.StatusId == 6) {
          return false
        } else return withinPeriod
      } else return withinPeriod
    },
    loading() {
      return (
        this.luPeriodLoading ||
        this.applicationLoading ||
        this.taxablePropertyLoading ||
        this.luPeriodLoading
      )
    },
    haveContact() {
      return this.contact && this.contact.Id > 0
    },
    haveProperty() {
      return this.property && this.property.Id > 0
    },
    haveApplication() {
      return this.selectedApplication && this.selectedApplication.Id > 0
    },
    contact() {
      if (this.appId && !this.loading) {
        return this.selectedApplication.TaxableProperty.Contact
      } else if (this.propertyId) {
        return this.selectedTaxableProperty.Contact
      } else if (this.contactId) {
        return this.selectedContact
      } else {
        return null
      }
    },
    contactProperties() {
      if (!this.loading) {
        if (
          this.contact &&
          this.contact.Id > 0 &&
          this.contact.TaxableProperties &&
          this.contact.TaxableProperties.length > 0
        ) {
          return this.contact.TaxableProperties.map((item) => {
            var latestPeriod = Math.max.apply(
              this,
              item.Applications.map((a) => a.Period.Period)
            )
            return {
              ...item,
              latestPeriod: latestPeriod > 0 ? latestPeriod : 'N/A',
            }
          })
        } else {
          return []
        }
      } else {
        return []
      }
    },
    property() {
      if (this.appId) {
        return this.selectedApplication.TaxableProperty
      } else if (this.propertyId) {
        return this.selectedTaxableProperty
      } else {
        return null
      }
    },
    // propertyApplications() {
    //   if (this.property && this.property.Applications.length > 0) {
    //     return this.property.Applications.map((a) => {
    //       return { ...a }
    //     })
    //   } else {
    //     return []
    //   }
    // },
    propertyApplicationsForCurrentPeriod() {
      if (!this.loading) {
        if (
          this.currentLuPeriod &&
          this.currentLuPeriod.Id > 0 &&
          this.property &&
          this.property.Id > 0 &&
          this.property.Applications &&
          this.property.Applications.length > 0
        ) {
          var apps = this.property.Applications.filter(
            (x) =>
              x.PeriodId == this.currentLuPeriod.Id &&
              x.ApplicationStatusHistories.reduce((m, v, i) =>
                new Date(v.CreatedDate).getTime() >
                  new Date(m.CreatedDate).getTime() && i
                  ? v
                  : m
              ).StatusId != 8
          )
          console.devlog(apps)
          return apps
        } else {
          console.devlog('nope')
          return []
        }
      } else {
        console.devlog('not loaded')
        return []
      }
    },
  },
  methods: {
    ...call('contact', ['saveContact', 'loadSelectedContact']),
    ...call('luPeriod', ['loadCurrentLuPeriod']),
    ...call('taxableProperty', [
      'saveTaxableProperty',
      'loadSelectedTaxableProperty',
    ]),
    ...call('application', [
      'saveApplication',
      'loadSelectedApplication',
      'setSelectedApplication',
    ]),

    loadFromRoute() {
      if (!this.currentLuPeriod || !this.currentLuPeriod.Id > 0) {
        this.loadCurrentLuPeriod()
      }
      if (!this.appId) {
        this.setSelectedApplication({})
        if (this.contactId) {
          console.devlog('load contact ' + this.contactId)
          this.loadSelectedContact(this.contactId)
        }
        if (this.propertyId) {
          console.devlog('load property ' + this.propertyId)
          this.loadSelectedTaxableProperty(this.propertyId)
        }
      } else {
        console.devlog('load app ' + this.appId)
        this.loadSelectedApplication(this.appId)
      }
    },
    refreshContact() {
      this.loadFromRoute()
    },
    refreshProperty() {
      this.loadFromRoute()
    },
    refreshApplication() {
      this.loadFromRoute()
    },
  },
  watch: {},
  created() {
    this.loadFromRoute()
  },
}
</script>
<style scoped></style>
