<template>
  <v-dialog
    persistent
    v-model="dialog"
    scrollable
    @keydown.esc="dialog = false"
    width="850"
  >
    <PropertyEntryForm
      @refresh="$emit('refresh')"
      @itemAdded="$emit('itemAdded', $event)"
      ref="taxablePropertyForm"
      :dialog.sync="dialog"
      :contact="contact"
    ></PropertyEntryForm
  ></v-dialog>
</template>
<script>
import PropertyEntryForm from '@components/form/staff/propertyEntryForm.vue'
export default {
  components: {
    PropertyEntryForm,
  },
  props: {
    contact: {
      type: Object,
    },
  },
  data: () => ({
    dialog: false,
  }),
  created() {},
  computed: {},
  methods: {
    editTaxableProperty(entry) {
      this.dialog = true
      this.$nextTick(() => {
        this.$refs.taxablePropertyForm.editTaxableProperty(entry)
      })
    },
    addTaxableProperty(entry) {
      this.dialog = true
      this.$nextTick(() => {
        this.$refs.taxablePropertyForm.addTaxableProperty(entry)
      })
    },
  },
  watch: {},
}
</script>
import
<style></style>
