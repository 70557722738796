<template>
  <v-row v-if="contact && contact.Id > 0">
    <v-col cols="1">
      <v-row>
        <BaseTooltipButton
          v-if="canEdit"
          small
          @click="$refs.contactForm.editContact(contact)"
          iconColor="primary"
          icon="mdi-pencil"
          >Edit Contact
        </BaseTooltipButton>
      </v-row>
      <v-row v-if="!contactId">
        <BaseTooltipButton
          small
          @click="
            $router.push({
              name: 'staffForm',
              params: { appId: null },
              query: { contactId: contact.Id },
            })
          "
          iconColor="primary"
          icon="mdi-home-group"
          >View Property List
        </BaseTooltipButton>
      </v-row>
    </v-col>
    <v-col>
      <div>
        {{ contact.FirstName }} {{ contact.MiddleInitial }}
        {{ contact.LastName }}
      </div>
      <div v-if="expand">
        <div
          class="pointer primary--text"
          v-if="showSSN"
          @click="showSSN = false"
        >
          {{ contact.Ssn }}
        </div>
        <div class="pointer primary--text" v-else @click="showSSN = true"
          >- show SSN -</div
        >
        <div>
          Mailing Address:
        </div>
        <div>
          {{ contact.MailingAddress1 }}
        </div>
        <div v-if="contact.MailingAddress2">
          {{ contact.MailingAddress2 }}
        </div>
        <div>
          {{ cityStateZip }}
        </div>
      </div>
      <div>
        {{ contact.Phone }}
      </div>
      <div v-if="contact.User">
        <a :href="`mailto:${contact.User.Email}`">
          {{ contact.User.Email }}
        </a>
      </div>
    </v-col>
    <ContactDialogForm
      ref="contactForm"
      @refresh="$emit('refresh')"
      @itemAdded="$emit('itemAdded', $event)"
    >
    </ContactDialogForm>
  </v-row>
</template>
<script>
import ContactDialogForm from '@components/form/staff/contactDialog'
export default {
  //model: {
  //	prop: ''
  //},
  props: {
    contact: {
      type: Object,
    },
    expand: {
      type: Boolean,
    },
    canEdit: {
      type: Boolean,
    },
  },
  components: {
    ContactDialogForm,
  },
  data() {
    return {
      showSSN: false,
    }
  },
  computed: {
    cityStateZip() {
      var city = this.contact.City ? this.contact.City : ''
      var state = this.contact.State ? this.contact.State.Abbr : ''
      var zip = this.contact.Zip ? this.contact.Zip : ''
      return `${city}, ${state} ${zip}`
    },
    contactId() {
      return parseInt(this.$route.query.contactId) || false
    },
  },
  methods: {
    refresh() {
      console.devlog('refresh')
      this.$emit('refreshContact')
    },

    itemAdded(value) {
      console.devlog('itemAdded', value)
    },
  },
  watch: {},
}
</script>
<style scoped></style>
