<template>
  <div>
    <ApplicationSummary :application="application" :canEdit="canEdit"></ApplicationSummary>
    <ApplicationHouseholdMembers :canEdit="canEdit"></ApplicationHouseholdMembers>
    <ApplicationStatusHistory :canEdit="canEdit"></ApplicationStatusHistory>
    <ApplicationDocuments :canEdit="canEdit"></ApplicationDocuments>
    <ApplicationNotes :canEdit="canEdit"></ApplicationNotes>
  </div>
</template>
<script>
import { get, sync, commit, call } from 'vuex-pathify'
import ApplicationHouseholdMembers from '@components/form/staff/ApplicationHouseholdMembers'
import ApplicationDocuments from '@components/form/staff/ApplicationDocuments'
import ApplicationNotes from '@components/form/staff/ApplicationNotes'
import ApplicationStatusHistory from '@components/form/staff/ApplicationStatusHistory'
import ApplicationSummary from '@components/form/staff/ApplicationSummary'
export default {
  //model: {
  //	prop: ''
  //},
  created() {
    this.loadSelectedApplicationApplicationDocuments()
    this.loadSelectedApplicationApplicationNotes()
    this.loadSelectedApplicationApplicationStatusHistories()
    this.loadSelectedApplicationHouseholdMembers()
  },
  props: {
    application: {
      type: Object,
    },
    canEdit: {
      type: Boolean,
    },
  },
  components: {
    ApplicationHouseholdMembers,
    ApplicationDocuments,
    ApplicationNotes,
    ApplicationStatusHistory,
    ApplicationSummary,
  },
  data() {
    return {}
  },
  computed: {},
  methods: {
    ...call('application', [
      'loadSelectedApplicationApplicationDocuments',
      'loadSelectedApplicationApplicationNotes',
      'loadSelectedApplicationApplicationStatusHistories',
      'loadSelectedApplicationHouseholdMembers',
    ]),
  },
  watch: {},
}
</script>
<style scoped></style>
