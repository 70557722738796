<template>
  <div class="wrapper" :style="cssVars">
    <div class="mt-2 pr-8 application-content">
      <ApplicationMainContent
        :application="application"
        :canEdit="canEdit"
      ></ApplicationMainContent>
    </div>
    <div class="fixed-wrapper">
      <span class="sidebar-expand-button">
        <BaseTooltipButton
          small
          @click="expandSidebar = !expandSidebar"
          iconColor="primary"
          :icon="expandSidebar ? 'mdi-chevron-right' : 'mdi-chevron-left'"
          >{{ expandSidebar ? 'Collapse' : 'Expand' }}
        </BaseTooltipButton>
      </span>
      <div class="fixed">
        <ApplicationSidebar :application="application"></ApplicationSidebar>
      </div>
    </div>
  </div>
</template>
<script>
import ApplicationMainContent from '@components/form/staff/applicationMainContent'
import ApplicationSidebar from '@components/form/staff/applicationSidebar'
export default {
  //model: {
  //	prop: ''
  //},
  props: {
    application: {
      type: Object,
    },
    canEdit: {
      type: Boolean
    },
  },
  components: {
    ApplicationMainContent,
    ApplicationSidebar,
  },
  data() {
    return {
      expandSidebar: true,
    }
  },
  computed: {
    vuetify() {
      return this.$vuetify
    },
    sidebarWidth() {
      return this.expandSidebar ? 220 : 0
    },
    cssVars() {
      return {
        '--sidebar-width': this.sidebarWidth + 'px',
      }
    },
  },
  methods: {},
  watch: {},
}
</script>
<style vars="{ 'sidebar-width' }" scoped>
.wrap {
  /* position: relative; */
  min-height: 250px;
}
.application-content {
  width: calc(100% - var(--sidebar-width));
}
.wrapper {
  width: 100%;
  margin: 0 auto;
  position: relative; /* Ensure absolute positioned child elements are relative to this*/
}
.fixed-wrapper {
  /* width: var(--sidebar-width); */
  width: calc(var(--sidebar-width) + 30px);
  position: absolute;
  top: 0;
  right: 0; /* Move this out to the left of the site body, leaving a 20px gutter */
  overflow-x: visible;
}
.fixed {
  width: calc(var(--sidebar-width));
  /* overflow-y: auto; */
  /* top: 0; */
  position: fixed;
  margin-left: 30px;
  /* Do not set top / left! */
  height: calc(100vh - 250px);
  overflow-y: auto;

  /* bottom: 0; */
}
.sticky {
  position: sticky;
}
.statusBar {
  overflow-x: hidden;
  position: fixed;
}
.sidebar-expand-button {
  margin-top: 5px;
  width: 30px;
  height: calc(100vh - 250px);
  position: fixed;
  /* left: -30px; */
  /* position: absolute; */
}
</style>
