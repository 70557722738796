<template>
  <v-dialog
    persistent
    v-model="dialog"
    scrollable
    @keydown.esc="dialog = false"
    width="850"
  >
    <v-form ref="form" @submit.prevent lazy-validation v-model="valid">
      <v-card class="elevation-3">
        <v-card-title class="primary text-h5 white--text">
          {{ modalText }}
        </v-card-title>
        <v-card-text class="pa-4">
          <v-row>
            <v-col sm="12">
              <v-textarea
                label="Note"
                v-model="selectedApplicationNote.Note"
              ></v-textarea>
            </v-col>
          </v-row>
          <!-- <v-row>
            <v-col sm="12">
              <Application
                label="Application"
                v-model="selectedApplicationNote.ApplicationId"
                  :rules="[v => !!v || 'required']"
              ></Application>
            </v-col>
          </v-row> -->
          <v-row>
            <v-col sm="12">
              <LuNoteType
                label="Note Type"
                v-model="selectedApplicationNote.NoteTypeId"
                :rules="[(v) => !!v || 'required']"
              ></LuNoteType>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="saveApplicationNoteDetails"
            :disabled="saving"
            color="primary"
            >Save</v-btn
          >
          <v-btn outlined color="primary" @click="cancelEntry">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import { get, sync, commit, call } from 'vuex-pathify'
import ApplicationNote from '@classes/ApplicationNote'
//templateTODO: import lookup components you plan to use
import LuNoteType from '@components/select/LuNoteType/LuNoteType-single'
export default {
  components: {
    //templateTodo: define lookup components you are using
    //Application,
    LuNoteType,
  },
  data: () => ({
    dialog: false,
    valid: true,
    selectedApplicationNote: new ApplicationNote(),
    modalText: '',
  }),
  created() {},
  computed: {
    ...get('applicationNote', ['saving']),
  },
  methods: {
    handleError: call('errors/handleError'),
    ...call('applicationNote', ['saveApplicationNote']),
    async validate() {
      await this.$refs.form.validate()
    },
    editApplicationNote(entry) {
      this.selectedApplicationNote = new ApplicationNote(entry)
      this.modalText = 'Edit Application Note'
      this.dialog = !this.dialog
    },
    addApplicationNote(appId) {
      this.modalText = 'Insert Application Note'
      this.selectedApplicationNote = new ApplicationNote({
        ApplicationId: appId,
      })
      this.dialog = !this.dialog
    },
    async saveApplicationNoteDetails() {
      await this.validate()
      if (this.valid) {
        //templateTODO: if you want to save related item arrays you will need to add a getSaveData() function to the javascript class and call that instead
        try {
          await this.saveApplicationNote(
            this.selectedApplicationNote.removeRelated()
          )
          this.$emit('refresh')
          this.$emit('itemAdded', res.data)
          this.selectedApplicationNote = new ApplicationNote()
          this.$refs.form.resetValidation()
        } catch (err) {}
        this.dialog = false
      }
    },
    cancelEntry() {
      this.dialog = false
      this.selectedApplicationNote = new ApplicationNote()
      this.$refs.form.resetValidation()
    },
  },
}
</script>
<style></style>
