<template>
  <v-dialog
    persistent
    v-model="dialog"
    scrollable
    @keydown.esc="dialog = false"
    width="850"
  >
    <v-form ref="form" @submit.prevent lazy-validation v-model="valid">
      <v-card class="elevation-3">
        <v-card-title class="primary text-h5 white--text">
          {{ modalText }}
        </v-card-title>
        <v-card-text class="pa-4">
          <!-- <v-row>
            <v-col sm="12">
              <Application
                label="Application"
                v-model="selectedApplicationDocument.ApplicationId"
                  :rules="[v => !!v || 'required']"
              ></Application>
            </v-col>
          </v-row> -->
          <!-- <v-row>
            <v-col sm="12">
              <Document
                label="Document"
                v-model="selectedApplicationDocument.DocumentId"
                  :rules="[v => !!v || 'required']"
              ></Document>
            </v-col>
          </v-row> -->
          <v-row>
            <v-col sm="12">
              <LuDocumentType
                label="Document Type"
                :items="filteredApplicationDocumentTypes"
                v-model="selectedApplicationDocument.Document.DocumentTypeId"
                :rules="[(v) => !!v || 'required']"
              ></LuDocumentType>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              sm="12"
              v-if="
                selectedApplicationDocumentType &&
                selectedApplicationDocumentType.IsForHouseholdMember
              "
            >
              <HouseholdMember
                label="Household Member"
                v-model="selectedApplicationDocument.HouseholdMemberId"
                :items="householdMembers"
              >
                <template v-slot:selection="{ item }">
                  {{ item.FirstName }} {{ item.LastName }}</template
                >
                <template v-slot:item="{ item }"
                  >{{ item.FirstName }} {{ item.LastName }}
                </template>
              </HouseholdMember>
            </v-col>
          </v-row>
          <v-row v-if="selectedApplicationDocument.DocumentId == 0" no-gutters>
            <v-col sm="12">
              <div class="dropbox">
                <input class="input-file" type="file" @change="getFiles" />
                <p v-if="uploadDocs.length == 0" class="extra-margin"
                  >Drag file to upload or click to browse</p
                >
                <div v-if="uploadDocs.length > 0" class="extra-margin">
                  <p class="uploadList">{{ lastItem.name }}</p>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col sm="12">
              <v-text-field
                label="File Name"
                v-model="selectedApplicationDocument.Document.FileName"
                :rules="[(v) => !!v || 'required']"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="saveApplicationDocumentDetails"
            :disabled="saving"
            color="primary"
            >Save</v-btn
          >
          <v-btn outlined color="primary" @click="cancelEntry">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import { get, sync, commit, call } from 'vuex-pathify'
import ApplicationDocument from '@classes/ApplicationDocument'
//templateTODO: import lookup components you plan to use
import LuDocumentType from '@components/select/LuDocumentType/LuDocumentType-single'
import HouseholdMember from '@components/select/HouseholdMember/HouseholdMember-single'
export default {
  components: {
    //templateTodo: define lookup components you are using
    //Application,
    //Document,
    HouseholdMember,
    LuDocumentType,
  },
  props: {
    householdMembers: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    dialog: false,
    valid: true,
    selectedApplicationDocument: new ApplicationDocument(),
    modalText: '',
    uploadDocs: [],
    items: [],
  }),
  created() {
    this.loadApplicationDocumentTypes()
  },
  computed: {
    ...sync('applicationDocument', ['saving']),
    ...get('applicationDocumentType', ['applicationDocumentTypes']),
    filteredApplicationDocumentTypes() {
      return this.applicationDocumentTypes.map((type) => {
        return type.DocumentType
      })
    },
    selectedApplicationDocumentType() {
      if (this.selectedApplicationDocument.Document.DocumentTypeId > 0) {
        return this.applicationDocumentTypes.find(
          (x) =>
            x.DocumentTypeId ==
            this.selectedApplicationDocument.Document.DocumentTypeId
        )
      } else {
        return null
      }
    },
    lastItem() {
      return this.items.slice(-1)[0]
    },
  },
  methods: {
    loadApplicationDocumentTypes: call(
      'applicationDocumentType/loadApplicationDocumentTypes'
    ),
    handleError: call('errors/handleError'),
    ...call('applicationDocument', ['saveApplicationDocument']),
    async validate() {
      await this.$refs.form.validate()
    },
    editApplicationDocument(entry) {
      this.selectedApplicationDocument = new ApplicationDocument(entry)
      this.modalText = 'Edit Application Document'
      this.dialog = !this.dialog
    },
    addApplicationDocument(appId) {
      this.modalText = 'Insert Application Document'
      this.selectedApplicationDocument = new ApplicationDocument({
        ApplicationId: appId,
      })
      this.dialog = !this.dialog
    },
    async saveApplicationDocumentDetails() {
      await this.validate()
      if (this.valid) {
        //templateTODO: if you want to save related item arrays you will need to add a getSaveData() function to the javascript class and call that instead
        if (this.selectedApplicationDocument.Id == 0) {
          try {
            this.saving = true
            let appDoc = this.selectedApplicationDocument.getSaveData()
            for (let i = 0; i < this.uploadDocs.length - 2; i++) {
              let file = this.uploadDocs[i]
              let fileReader = new FileReader()
              if (fileReader && file) {
                fileReader.readAsDataURL(file)
                fileReader.onload = async () => {
                  let contents = fileReader.result.split(',')[1]
                  appDoc.Document.FileName = file.name
                  appDoc.Document.MimeType = file.type
                  appDoc.Document.DocumentFile = { File: contents }
                  let res = await this.saveApplicationDocument(appDoc)
                  this.saving = false
                  this.$emit('refresh')
                  this.$emit('itemAdded', res.data)
                }
              }
            }
            this.selectedApplicationDocument = new ApplicationDocument()
            this.uploadDocs = []
            this.items = []
            this.$refs.form.resetValidation()
          } catch (err) {}
          this.saving = false
          this.dialog = false
        } else {
          let appDoc = this.selectedApplicationDocument.getSaveData()
          let res = await this.saveApplicationDocument(appDoc)
          this.saving = false
          this.$emit('refresh')
          this.$emit('itemAdded', res.data)
          this.selectedApplicationDocument = new ApplicationDocument()
          this.$refs.form.resetValidation()
          this.dialog = false
        }
      }
    },

    cancelEntry() {
      this.uploadDocs = []
      this.items = []
      this.dialog = false
      this.selectedApplicationDocument = new ApplicationDocument()
      this.$refs.form.resetValidation()
    },
    getFiles: function (event) {
      this.uploadDocs = []
      for (let file in event.target.files) {
        this.uploadDocs.push(event.target.files[file])
      }
      for (let i = 0; i < this.uploadDocs.length - 2; i++) {
        if (this.uploadDocs[i].name) this.items.push(this.uploadDocs[i])
      }
    },
  },
  watch: {
    selectedApplicationDocumentType(nval, oval) {
      if (!nval || !nval.IsForHouseholdMember) {
        this.selectedApplicationDocument.HouseholdMemberId = null
      }
    },
  },
}
</script>
<style>
.dropbox {
  outline: 2px dashed rgb(190, 190, 190); /* the dash box */
  outline-offset: -10px;
  background: #fafafa;
  color: dimgray;
  padding: 10px 10px;
  position: relative;
  cursor: pointer;
  /* width: 20%; */
  height: 100px;
  /* margin-left: auto;
  margin-right: auto;  */
  margin-top: 10px;
}
.input-file {
  outline: 2px solid black;
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 100px;
  position: absolute;
  cursor: pointer;
}
</style>
