<template>
  <BasePanelWithHeader>
    <template v-slot:header>
      Application: {{ application.ApplicationNumber }}
      <BaseTooltipButton
        v-if="canEdit"
        icon="mdi-pencil"
        iconColor="white"
        @click="$refs.applicationForm.editApplication(application)"
        >Edit Application Details</BaseTooltipButton
      >
    </template>
    <v-row>
      <v-col cols="12" sm="8" md="4" lg="3">
        <div>
          <span class="item-label">Application Period:</span>
          <span class="item-value">{{ application.Period.Period }}</span>
        </div>
        <div v-if="application.PostmarkDate">
          <span class="item-label">Application Postmark:</span>
          <span class="item-value">{{
            application.PostmarkDate | formatDate
          }}</span>
          <BaseTooltipIcon
            :icon="postmarkValidation ? 'mdi-check-circle' : 'mdi-close-circle'"
            :color="postmarkValidation ? 'green' : 'red'"
          >
            Application was Postmarked or Submitted
            {{ postmarkValidation ? 'before' : 'after' }} the deadline
          </BaseTooltipIcon>
        </div>
        <div>
          <span class="item-label">County Household Maximum:</span>
          <span class="item-value">{{
            countyHouseholdMaximum | formatMoney
          }}</span>
        </div>
        <div>
          <div>
            <span class="item-label">Statewide Household Maximum:</span>
            <span class="item-value">{{
              statewideHouseholdMaximum | formatMoney
            }}</span>
          </div>
        </div>
        <div>
          <span class="item-label">Total Household Income Reported:</span>
          <span class="item-value">{{
            application.TotalHouseholdIncomeReported | formatMoney
          }}</span>
          <BaseTooltipIcon
            v-if="application.TotalHouseholdIncomeReported != null"
            :icon="incomeNotExceeded ? 'mdi-check-circle' : 'mdi-close-circle'"
            :color="incomeNotExceeded ? 'green' : 'red'"
          >
            Household Income
            {{ incomeNotExceeded ? 'does not exceed' : 'exceeds' }} the maximum
            for this period
          </BaseTooltipIcon>
          <BaseTooltipIcon
            :icon="incomeMatches ? 'mdi-check-circle' : 'mdi-alert-circle'"
            :color="incomeMatches ? 'green' : 'orange'"
          >
            Total Household Income Reported
            {{ incomeMatches ? 'matches' : 'does not match' }} the total entries
            in the Household Members section
          </BaseTooltipIcon>
        </div>
        <div v-if="calculatedThreshold">
          <!-- <div class="item-label">Applicable Median Threshold:</div> -->
          <div>
            <span class="item-label">Applicable Median Threshold:</span>
            <span class="item-value">
              {{ calculatedThreshold.medianType }}</span
            >
          </div>
          <div>
            <span class="item-label">Income:</span>
            <span class="item-value">
              {{ calculatedThreshold.IncomePercentage * 100 + '%' }}</span
            >
            <!-- </div>
          <div> -->
            <span class="item-label ml-2">Refund:</span>
            <span class="item-value">
              {{ calculatedThreshold.RefundPercentage * 100 + '%' }}</span
            >
          </div>
          <div>
            <span class="item-label">Maximum:</span>
            <span class="item-value">
              {{ calculatedThreshold.maxIncome | formatMoney }}</span
            >
          </div>
          <!-- <BaseTooltipIcon
            v-if="application.TotalHouseholdIncomeReported != null"
            :icon="incomeNotExceeded ? 'mdi-check-circle' : 'mdi-close-circle'"
            :color="incomeNotExceeded ? 'green' : 'red'"
          >
            Household Income
            {{ incomeNotExceeded ? 'does not exceed' : 'exceeds' }} the maximum
            for this period
          </BaseTooltipIcon>
          <BaseTooltipIcon
            :icon="incomeMatches ? 'mdi-check-circle' : 'mdi-alert-circle'"
            :color="incomeMatches ? 'green' : 'orange'"
          >
            Total Household Income Reported
            {{ incomeMatches ? 'matches' : 'does not match' }} the total entries
            in the Household Members section
          </BaseTooltipIcon> -->
        </div>
        <div v-if="application.RefundAmount">
          <span class="item-label">Refund Amount:</span>
          <span class="item-value">{{
            application.RefundAmount | formatMoney
          }}</span>
        </div>
        <div v-if="application.RefundStatusDate">
          <span class="item-label">Refund Status Date:</span>
          <span class="item-value">{{
            application.RefundStatusDate | formatDate
          }}</span>
        </div>
      </v-col>
      <v-col cols="12" sm="8" md="4" lg="3">
        <div>
          <span class="item-label">Tax Bill Amount:</span>
          <span class="item-value">{{
            application.TaxBillAmount | formatMoney
          }}</span>
          <span
            v-if="
              application.TaxBillAmount != null &&
              application.Payment1Amount != null
            "
          >
            <BaseTooltipIcon
              :icon="
                taxBillMatchesPaymentAmounts
                  ? 'mdi-check-circle'
                  : 'mdi-alert-circle'
              "
              :color="taxBillMatchesPaymentAmounts ? 'green' : 'orange'"
            >
              Tax Bill Amount
              {{ taxBillMatchesPaymentAmounts ? 'matches' : 'does not match' }}
              the total payment amounts
            </BaseTooltipIcon>
            <BaseTooltipIcon
              v-if="
                application.PropertyTaxGreaterThanIncomePercentage &&
                taxBillExceedsIncomePercentage != null
              "
              :icon="
                taxBillExceedsIncomePercentage
                  ? 'mdi-check-circle'
                  : 'mdi-close-circle'
              "
              :color="taxBillExceedsIncomePercentage ? 'green' : 'red'"
            >
              Tax Bill Amount is
              {{ taxBillExceedsIncomePercentage ? 'more' : 'less' }}
              than 10% of Household income
            </BaseTooltipIcon>
          </span>
          <span v-else>
            <BaseTooltipIcon icon="mdi-close-circle" color="red">
              Tax Bill Payment Amounts have not been entered
            </BaseTooltipIcon>
          </span>
        </div>
        <div>
          <span class="item-label">Payment 1 Amount:</span>
          <span class="item-value">{{
            application.Payment1Amount | formatMoney
          }}</span>
        </div>
        <div>
          <span class="item-label">Payment 1 Date:</span>
          <span class="item-value">{{
            application.Payment1Date | formatDate
          }}</span>
          <BaseTooltipIcon
            :icon="
              application.Payment1Date && (payment1Timely || fullPaymentTimely)
                ? 'mdi-check-circle'
                : 'mdi-close-circle'
            "
            :color="
              application.Payment1Date && (payment1Timely || fullPaymentTimely)
                ? 'green'
                : 'red'
            "
          >
            <span v-if="application.Payment1Date">
              Payment 1 was made {{ payment1Timely ? 'before' : 'after' }} the
              deadline{{
                fullPaymentTimely
                  ? ' but the full payment was made before the full payment deadline'
                  : ''
              }}
            </span>
            <span v-else> Payment 1 date has not been entered </span>
          </BaseTooltipIcon>
        </div>
        <div>
          <span class="item-label">Payment 2 Amount:</span>
          <span class="item-value">{{
            application.Payment2Amount | formatMoney
          }}</span>
        </div>
        <div>
          <span class="item-label">Payment 2 Date:</span>
          <span class="item-value">{{
            application.Payment2Date | formatDate
          }}</span>
          <BaseTooltipIcon
            v-if="
              application.Payment2Date ||
              application.TaxBillAmount != application.Payment1Amount
            "
            :icon="
              application.Payment2Date && payment2Timely
                ? 'mdi-check-circle'
                : 'mdi-close-circle'
            "
            :color="
              application.Payment2Date && payment2Timely ? 'green' : 'red'
            "
          >
            <span v-if="application.Payment2Date">
              Payment 2 was made {{ payment2Timely ? 'before' : 'after' }} the
              deadline
            </span>
            <span v-else> Payment 2 date has not been entered </span>
          </BaseTooltipIcon>
        </div>
      </v-col>
      <v-col cols="12" sm="8" md="4" lg="3">
        <div>
          <div>
            <span class="item-label">On Time Payments:</span>
            <span class="item-value">{{
              paymentsMadeOnTime | formatMoney
            }}</span>
          </div>
          <div>
            <span class="item-label">Business Deduction:</span>
            <span class="item-value">{{
              application.BusinessDeduction | formatMoney
            }}</span>
            <BaseTooltipIcon
              v-if="
                application.BusinessDeduction >= paymentsMadeOnTime &&
                application.Payment1Amount != null
              "
              icon="mdi-close-circle"
              color="red"
            >
              The Business Deduction is greater than the on time payments
            </BaseTooltipIcon>
          </div>
          <div>
            <span class="item-label">Payment Considered For Refund:</span>
            <span class="item-value">{{
              paymentConsideredForRefund | formatMoney
            }}</span>
          </div>
          <div>
            <span class="item-label"
              >{{ application.Period.PercentageEligible * 100 }}% of
              Payment:</span
            >
            <span class="item-value">{{
              availablePaymentPercentage | formatMoney
            }}</span>
          </div>
          <div>
            <span class="item-label">County Median:</span>
            <span class="item-value">{{ countyMedianTax | formatMoney }}</span>
          </div>
          <div>
            <span class="item-label">Maximum Refund:</span>
            <span class="item-value">{{
              maximumRefundAmount | formatMoney
            }}</span>
          </div>
          <div>
            <span class="item-label">Calculated Refund:</span>
            <span class="item-value">{{
              calculatedRefundAmount | formatMoney
            }}</span>
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="8" md="4" lg="3">
        <div v-if="currentStatusDescription">
          <div class="item-label">Application Status</div>
          <div class="item-value">{{ currentStatusDescription }}</div>
          <div v-if="currentReasonDescriptions">
            <div
              v-for="reason in currentReasonDescriptions"
              class="item-subtext"
              >{{ reason }}</div
            >
          </div>
        </div>
        <BaseTooltipButton
          v-if="canEdit && availableStatuses && availableStatuses.length > 0"
          icon="mdi-help-circle"
          iconColor="primary"
          @click="
            $refs.applicationStatusHistoryForm.addApplicationStatusHistory()
          "
          buttonRightText="Change Status"
          :iconButton="false"
          >Change Status</BaseTooltipButton
        >
        <BaseTooltipButton
          v-if="
            canEdit &&
            currentStatus &&
            (currentStatus.StatusId == 1 ||
              currentStatus.StatusId == 3 ||
              currentStatus.StatusId == 4)
          "
          icon="mdi-check-circle"
          iconColor="primary"
          @click="$refs.validateConfirm.confirmItem()"
          buttonRightText="Validate"
          :iconButton="false"
          >Validate Application and Move to Next Status</BaseTooltipButton
        >
        <div v-else-if="!withinPeriod">
          <div v-if="beforePeriod">
            The application period has not yet opened
          </div>
          <div v-if="afterPeriod"> The application period has ended </div>
        </div>
      </v-col>
    </v-row>
    <!-- <div> Application Number: {{ application.ApplicationNumber }} </div> -->
    <ApplicationDialogForm
      ref="applicationForm"
      @refresh="refreshSelectedApplication"
    ></ApplicationDialogForm>

    <ApplicationStatusHistoryDialogForm
      :currentStatus="currentStatus"
      :appId="application.Id"
      ref="applicationStatusHistoryForm"
      @refresh="refreshStatusHistoriesAndDocuments"
    >
    </ApplicationStatusHistoryDialogForm>
    <BaseConfirm
      ref="validateConfirm"
      confirmText="Proceed"
      closeText="Cancel"
      :confirm="confirmNextStatus"
    >
      <div>
        <div v-if="nextStatus">
          This application will be moved to the {{ nextStatus }} status
          <span v-if="nextStatusReasons.length > 0"
            >with the following reasons:</span
          >
          <li v-for="reason in nextStatusReasons">{{ reason }}</li>
          <br />Would you like to proceed?
        </div>
        <div v-else>unable to determine next status</div>
      </div>
    </BaseConfirm>
  </BasePanelWithHeader>
</template>
<script>
import dayjs from 'dayjs'
import ApplicationDialogForm from '@components/form/staff/applicationDialog'
import ApplicationStatusReason from '@classes/ApplicationStatusReason'
import ApplicationStatusHistoryDialogForm from '@components/form/add-edit/ApplicationStatusHistoryDialog'
import Application from '@classes/Application'
import ApplicationStatusHistory from '@classes/ApplicationStatusHistory'
import { authMethods, authComputed } from '@state/helpers'
import { get, sync, commit, call } from 'vuex-pathify'
export default {
  //model: {
  //	prop: ''
  //},
  props: {
    application: {
      type: Object,
    },
    canEdit: {
      type: Boolean,
    },
  },
  components: {
    ApplicationDialogForm,
    ApplicationStatusHistoryDialogForm,
  },
  data() {
    return {}
  },
  created() {
    this.loadLuApplicationStatuses()
    this.loadLuReasons()
    this.loadLuIncomeThresholds()
  },
  computed: {
    ...authComputed,
    ...get('application', [
      'selectedApplicationApplicationStatusHistories',
      'selectedApplicationHouseholdMembers',
      'applicationStatusHistoriesLoading',
    ]),
    ...get('luApplicationStatus', ['luApplicationStatuses']),
    ...get('luReason', ['luReasons']),
    ...get('luIncomeThreshold', ['luIncomeThresholds']),
    withinPeriod() {
      let period = this.application?.Period || this.currentLuPeriod
      let withinPeriod =
        dayjs(period.ApplicationOpenDate) <= dayjs() &&
        dayjs(period.PeriodEndDate) >= dayjs()
      return withinPeriod
    },
    beforePeriod() {
      let period = this.application?.Period || this.currentLuPeriod
      return dayjs(period.ApplicationOpenDate) > dayjs()
    },
    afterPeriod() {
      let period = this.application?.Period || this.currentLuPeriod
      return dayjs(period.PeriodEndDate) < dayjs()
    },
    validations() {
      // create validation list
      let validations = [
        //deadline
        { validationId: 1, check: !this.postmarkValidation, defaultReason: 8 },
        //timely paid
        {
          validationId: 2,
          check:
            !this.payment1Timely &&
            !this.payment2Timely &&
            !this.fullPaymentTimely,
          defaultReason: 11,
        },
        // household income exceeded
        {
          validationId: 3,
          check: !this.incomeNotExceeded,
          defaultReason: 12,
        },
        //household assets and Income percentage
        {
          validationId: 4,
          check:
            !this.application.DoHouseholdAssetsQualify &&
            (!this.application.PropertyTaxGreaterThanIncomePercentage ||
              this.taxBillExceedsIncomePercentage === false),
          defaultReason: 22,
        },
        //residency
        {
          validationId: 5,
          check: this.application.DoResidentialRequirementsQualify == false,
          defaultReason: 14,
        },
        //other benefits exceed refund
        {
          validationId: 6,
          check:
            this.paymentsMadeOnTime > 0 &&
            this.application.BusinessDeduction > 0 &&
            this.application.BusinessDeduction >= this.paymentsMadeOnTime &&
            this.application.Payment1Amount != null,
          defaultReason: 15,
        },
        //primary residence
        {
          validationId: 7,
          check: this.application.IsPrimaryResidence == false,
          defaultReason: 24,
        },
        //parcel number
        {
          validationId: 8,
          check: !this.application.TaxableProperty.ParcelId,
          defaultReason: 2,
        },
        //irs attached
        {
          validationId: 9,
          check: !this.application.IsTaxBillAttached,
          defaultReason: 3,
        },
        //tax return attached
        {
          validationId: 10,
          check: !this.application.IsIrsAttached,
          defaultReason: 4,
        },
        //household income
        {
          validationId: 11,
          check:
            !this.application.AreHouseholdDetailsProvided ||
            this.application.TotalHouseholdIncomeReported == null,
          defaultReason: 5,
        },
        //business deduction
        {
          validationId: 12,
          check:
            this.application.BusinessDeduction == null ||
            this.application.BusinessDeduction == undefined,
          defaultReason: 16,
        },
        //tax payment receipt
        {
          validationId: 13,
          check:
            !this.application.IsPaymentReceiptAttached ||
            !this.application.Payment1Date ||
            this.application.Payment1Amount == null,
          defaultReason: 18,
        },
        //signature
        {
          validationId: 14,
          check:
            !this.application.IsApplicationSigned &&
            !(
              this.application.CertifySsn &&
              this.application.UsResident &&
              this.application.CorrectAndComplete
            ),
          defaultReason: 7,
        },
        //ssn
        {
          validationId: 15,
          check: !this.application.TaxableProperty.Contact.Ssn,
          defaultReason: 1,
        },
        //residency
        {
          validationId: 16,
          check: !this.application.DoResidentialRequirementsQualify,
          defaultReason: 23,
        },
      ]
      // go through reasons, and find matching reason.  match to statusid 3 first, and then 4 if it doesn't exist
      validations.forEach((v) => {
        let infReasonMatch = this.luReasons
          .filter(
            (x) =>
              x.StatusId == 3 && x.ValidationId == v.validationId && x.IsActive
          )
          .sort((a, b) => b.Id - a.Id)
        let rejReasonMatch = this.luReasons
          .filter(
            (x) =>
              x.StatusId == 4 && x.ValidationId == v.validationId && x.IsActive
          )
          .sort((a, b) => b.Id - a.Id)
        let defaultMatch = this.luReasons.find((x) => x.Id == v.defaultReason)
        if (infReasonMatch.length > 0) {
          v.reasonId = infReasonMatch[0].Id
          v.statusId = infReasonMatch[0].StatusId
        } else if (rejReasonMatch.length > 0) {
          v.reasonId = rejReasonMatch[0].Id
          v.statusId = rejReasonMatch[0].StatusId
        } else {
          // if there are none, use default reason if it is active
          if (defaultMatch && defaultMatch.IsActive) {
            v.reasonId = defaultMatch.Id
            v.statusId = defaultMatch.StatusId
          }
        }
      })
      console.blue(validations)
      return validations
    },
    validationStatus() {
      var status = new ApplicationStatusHistory({
        ApplicationId: this.application.Id,
      })
      var reasons = []
      console.devlog(reasons)
      console.devlog(status)
      var rejValidations = this.validations.filter(
        (x) => x.statusId == 4 && x.check == true
      )
      rejValidations.forEach((x) => {
        reasons.push(new ApplicationStatusReason({ ReasonId: x.reasonId }))
      })
      //check for automatic rejections
      //deadline
      // if (!this.postmarkValidation) {
      //   reasons.push(new ApplicationStatusReason({ ReasonId: 8 }))
      // }

      // //timely paid
      // if (
      //   !this.payment1Timely &&
      //   !this.payment2Timely &&
      //   !this.fullPaymentTimely
      // ) {
      //   reasons.push(new ApplicationStatusReason({ ReasonId: 11 }))
      // }
      // // household income exceeded
      // if (!this.incomeNotExceeded) {
      //   reasons.push(new ApplicationStatusReason({ ReasonId: 12 }))
      // }
      // //household assets
      // if (
      //   !this.application.DoHouseholdAssetsQualify &&
      //   (!this.application.PropertyTaxGreaterThanIncomePercentage ||
      //     this.taxBillExceedsIncomePercentage === false)
      // ) {
      //   reasons.push(new ApplicationStatusReason({ ReasonId: 22 }))
      // }
      // // //taxes over 10% of income
      // // if (this.application.PropertyTaxGreaterThanIncomePercentage == true && this.taxBillExceedsIncomePercentage === false) {
      // //   reasons.push(new ApplicationStatusReason({ ReasonId: 17 }))
      // // }
      // // //taxes over 10% of income
      // // if (this.application.PropertyTaxGreaterThanIncomePercentage == false) {
      // //   reasons.push(new ApplicationStatusReason({ ReasonId: 17 }))
      // // }
      // //residency
      // if (this.application.DoResidentialRequirementsQualify == false) {
      //   reasons.push(new ApplicationStatusReason({ ReasonId: 14 }))
      // }

      // //other benefits exceed refund
      // if (
      //   this.paymentsMadeOnTime > 0 &&
      //   this.application.BusinessDeduction > 0 &&
      //   this.application.BusinessDeduction >= this.paymentsMadeOnTime &&
      //   this.application.Payment1Amount != null
      // ) {
      //   reasons.push(new ApplicationStatusReason({ ReasonId: 15 }))
      // }

      // //primary residence
      // if (this.application.IsPrimaryResidence == false) {
      //   reasons.push(new ApplicationStatusReason({ ReasonId: 24 }))
      // }
      console.devlog(reasons)

      //if reasons.length > 0 we auto reject
      if (reasons.length > 0) {
        status.StatusId = 4
        status.ApplicationStatusReasons = reasons
        return status
      }
      var infValidations = this.validations.filter(
        (x) => x.statusId == 3 && x.check == true
      )
      infValidations.forEach((x) => {
        reasons.push(new ApplicationStatusReason({ ReasonId: x.reasonId }))
      })
      //check for insufficient
      //parcel number
      // if (!this.application.TaxableProperty.ParcelId) {
      //   reasons.push(new ApplicationStatusReason({ ReasonId: 2 }))
      // }

      // //tax bill
      // if (!this.application.IsTaxBillAttached) {
      //   reasons.push(new ApplicationStatusReason({ ReasonId: 3 }))
      // }

      // //irs attached
      // if (!this.application.IsIrsAttached) {
      //   reasons.push(new ApplicationStatusReason({ ReasonId: 4 }))
      // }

      // //household income
      // if (
      //   !this.application.AreHouseholdDetailsProvided ||
      //   this.application.TotalHouseholdIncomeReported == null
      // ) {
      //   reasons.push(new ApplicationStatusReason({ ReasonId: 5 }))
      // }

      // //business deduction
      // if (
      //   this.application.BusinessDeduction == null ||
      //   this.application.BusinessDeduction == undefined
      // ) {
      //   reasons.push(new ApplicationStatusReason({ ReasonId: 16 }))
      // }

      // //tax payment receipt
      // if (
      //   !this.application.IsPaymentReceiptAttached ||
      //   !this.application.Payment1Date ||
      //   this.application.Payment1Amount == null
      // ) {
      //   reasons.push(new ApplicationStatusReason({ ReasonId: 18 }))
      // }

      // //signature
      // if (
      //   !this.application.IsApplicationSigned &&
      //   !(
      //     this.application.CertifySsn &&
      //     this.application.UsResident &&
      //     this.application.CorrectAndComplete
      //   )
      // ) {
      //   reasons.push(new ApplicationStatusReason({ ReasonId: 7 }))
      // }

      // //ssn
      // if (!this.application.TaxableProperty.Contact.Ssn) {
      //   reasons.push(new ApplicationStatusReason({ ReasonId: 1 }))
      // }

      // //household asset
      // // if (!this.application.DoHouseholdAssetsQualify) {
      // //   reasons.push(new ApplicationStatusReason({ ReasonId: 22 }))
      // // }
      // //residency
      // if (!this.application.DoResidentialRequirementsQualify) {
      //   reasons.push(new ApplicationStatusReason({ ReasonId: 23 }))
      // }
      if (reasons.length > 0) {
        status.StatusId = 3
        status.ApplicationStatusReasons = reasons
        return status
      } else {
        status.StatusId = 2
        return status
      }
    },
    nextStatus() {
      let next = this.luApplicationStatuses.find(
        (x) => x.Id == this.validationStatus.StatusId
      )
      if (next) {
        return next.Description
      } else {
        false
      }
    },
    nextStatusReasons() {
      let reasons = this.validationStatus.ApplicationStatusReasons.map(
        (x) => x.ReasonId
      )
      let desc = []
      reasons.forEach((r) => {
        let reason = this.luReasons.find((i) => i.Id == r)
        if (reason) {
          desc.push(`${reason.ReasonNumber} - ${reason.Description}`)
        }
      })
      return desc
    },
    postmarkValidation() {
      if (
        this.application.PostmarkDate &&
        this.application.Period.ApplicationDeadline
      ) {
        return (
          new Date(this.application.PostmarkDate) <=
          new Date(this.application.Period.ApplicationDeadline)
        )
      } else {
        return false
      }
    },
    countyMedian() {
      if (
        this.application.TaxableProperty &&
        this.application.TaxableProperty.CountyId
      ) {
        return this.application?.Period?.CountyMedians.find(
          (x) => x.CountyId == this.application.TaxableProperty.CountyId
        )
      } else {
        return null
      }
    },

    countyMedianTax() {
      if (this.countyMedian) {
        return this.countyMedian.MedianTax
      }
    },
    taxBillMatchesPaymentAmounts() {
      var paymentSums =
        this.application.Payment1Amount + this.application.Payment2Amount
      return (
        Math.round(Number.parseFloat(paymentSums) * 100) / 100 ==
        this.application.TaxBillAmount
      )
    },
    payment1Timely() {
      if (
        this.application.Payment1Date &&
        this.application.Period.Payment1Deadline
      ) {
        return (
          new Date(this.application.Payment1Date) <=
          new Date(this.application.Period.Payment1Deadline)
        )
      } else {
        return true
      }
    },
    payment2Timely() {
      if (
        this.application.Payment2Date &&
        this.application.Period.Payment2Deadline
      ) {
        return (
          new Date(this.application.Payment2Date) <=
          new Date(this.application.Period.Payment2Deadline)
        )
      } else {
        return true
      }
    },
    fullPaymentTimely() {
      if (
        this.application.Payment2Date &&
        this.application.Period.FullPaymentDeadline &&
        this.application.Payment1Date
      ) {
        return (
          new Date(this.application.Payment2Date) <=
            new Date(this.application.Period.FullPaymentDeadline) &&
          new Date(this.application.Payment1Date) <=
            new Date(this.application.Period.FullPaymentDeadline)
        )
      } else {
        return true
      }
    },
    paymentConsideredForRefund() {
      var paymentDifference =
        this.paymentsMadeOnTime - this.application.BusinessDeduction
      return paymentDifference >= 0 ? paymentDifference : 0
    },
    paymentsMadeOnTime() {
      let fullPayment
      if (
        (this.payment1Timely && this.payment2Timely) ||
        this.fullPaymentTimely
      ) {
        fullPayment =
          this.application.Payment1Amount + this.application.Payment2Amount
      } else if (!this.payment1Timely && this.payment2Timely) {
        fullPayment = this.application.Payment2Amount
      } else if (this.payment1Timely && !this.payment2Timely) {
        fullPayment = this.application.Payment1Amount
      } else {
        return 0
      }
      return Math.round(Number.parseFloat(fullPayment) * 100) / 100
      // return fullPayment
    },
    thresholdDate() {
      if (this.application.PostmarkDate) {
        return Date.parse(this.application.PostmarkDate)
      } else if (this.application?.Period.ApplicationDeadline) {
        return Date.parse(this.application?.Period.ApplicationDeadline)
      } else {
        return Date.now()
      }
    },
    activeIncomeThresholds() {
      return this.luIncomeThresholds
        .filter(
          (x) =>
            Date.parse(x.EffectiveDate) <= this.thresholdDate &&
            (x.ExpirationDate == null ||
              Date.parse(x.ExpirationDate) >= this.thresholdDate)
        )
        .sort((a, b) => b.IncomePercentage - a.IncomePercentage)
    },
    fullPaymentThreshold() {
      return this.activeIncomeThresholds.length > 0
        ? this.activeIncomeThresholds.find((x) => x.RefundPercentage == 1)
        : null
    },
    fullPaymentIncomePercentage() {
      return this.fullPaymentThreshold?.IncomePercentage ?? 1.25
    },
    mappedStateIncomeThresholds() {
      return this.activeIncomeThresholds.map((x) => {
        var maxIncome =
          (this.application?.Period?.HouseholdMaximum /
            this.fullPaymentIncomePercentage) *
          x.IncomePercentage
        return {
          ...x,
          maxIncome,
        }
      })
    },
    mappedCountyIncomeThresholds() {
      return this.activeIncomeThresholds.map((x) => {
        var maxIncome =
          (this.countyMedian.MedianIncome / this.fullPaymentIncomePercentage) *
          x.IncomePercentage
        return {
          ...x,
          maxIncome,
        }
      })
    },
    statewideHouseholdMaximum() {
      //if we have income thresholds we use the highest percentage from those
      if (this.mappedStateIncomeThresholds.length > 0) {
        return this.mappedStateIncomeThresholds[0].maxIncome
      }
      return this.application?.Period?.HouseholdMaximum
    },
    countyHouseholdMaximum() {
      if (this.countyMedian) {
        //if we have income thresholds we use the highest percentage from those
        if (this.mappedCountyIncomeThresholds.length > 0) {
          return this.mappedCountyIncomeThresholds[0].maxIncome
        }
        return this.countyMedian.MedianIncome
      }
    },
    calculatedThreshold() {
      if (
        this.activeIncomeThresholds.length > 0 &&
        this.application?.TotalHouseholdIncomeReported
      ) {
        let list = []
        var type = ''
        if (this.statewideHouseholdMaximum > this.countyHouseholdMaximum) {
          type = 'State'
          list = this.mappedStateIncomeThresholds
        } else {
          type = 'County'
          list = this.mappedCountyIncomeThresholds
        }
        var median = list.reduce((prev, curr) => {
          console.green(curr, prev)
          return curr.maxIncome >=
            this.application.TotalHouseholdIncomeReported &&
            (!prev || prev.maxIncome > curr.maxIncome)
            ? curr
            : prev
        }, null)
        if (median) {
          return { ...median, medianType: type }
        } else {
          return null
        }
      } else {
        return null
      }
    },
    availablePaymentPercentage() {
      // reduce by threshold percentage
      if (this.paymentConsideredForRefund > 0) {
        return (
          Math.round(
            Number.parseFloat(
              this.paymentConsideredForRefund *
                this.application?.Period?.PercentageEligible
            ) * 100
          ) / 100
        )
      }
    },
    maximumRefundAmount() {
      return this.availablePaymentPercentage > this.countyMedianTax
        ? this.countyMedianTax
        : this.availablePaymentPercentage
    },
    calculatedRefundAmount() {
      //if we have income thresholds use the sliding scale if they are over the base amount
      return this.calculatedThreshold
        ? Math.round(
            Number.parseFloat(
              this.maximumRefundAmount *
                this.calculatedThreshold.RefundPercentage
            ) * 100
          ) / 100
        : this.maximumRefundAmount
    },
    maxIncome() {
      return this.statewideHouseholdMaximum > this.countyHouseholdMaximum
        ? this.statewideHouseholdMaximum
        : this.countyHouseholdMaximum
    },
    incomeNotExceeded() {
      if (
        this.application.TotalHouseholdIncomeReported != null &&
        this.statewideHouseholdMaximum
      ) {
        return this.application.TotalHouseholdIncomeReported <= this.maxIncome
      } else {
        return true
      }
    },
    taxBillExceedsIncomePercentage() {
      if (
        this.application.TotalHouseholdIncomeReported != null &&
        this.application.TaxBillAmount != null &&
        this.application.PropertyTaxGreaterThanIncomePercentage == true
      ) {
        var percentage = this.application.TotalHouseholdIncomeReported * 0.1
        if (this.application.TaxBillAmount > percentage) {
          return true
        } else {
          return false
        }
      } else {
        return null
      }
    },
    incomeMatches() {
      let totalIncome = this.selectedApplicationHouseholdMembers
        .map((item) => {
          let totalIncome =
            item.TaxableIncome +
            item.NonTaxableInterest +
            item.NonTaxableDistributions +
            item.NonTaxableSs +
            item.OtherIncome +
            item.PriorYearPtrsRefund
          return {
            totalIncome: Math.round(Number.parseFloat(totalIncome) * 100) / 100,
          }
        })
        .reduce((sum, item) => {
          return (
            Math.round(Number.parseFloat(sum + item.totalIncome) * 100) / 100
          )
        }, 0)
      return totalIncome == this.application.TotalHouseholdIncomeReported
    },

    currentStatusDescription() {
      if (this.currentStatus && this.currentStatus.Status) {
        return this.currentStatus.Status.Description
      }
    },
    currentReasonDescriptions() {
      if (this.currentStatus && this.currentStatus.ApplicationStatusReasons) {
        return this.currentStatus.ApplicationStatusReasons.map(
          (x) => x.Reason.Description
        )
      }
    },
    currentStatus() {
      if (
        this.selectedApplicationApplicationStatusHistories &&
        this.selectedApplicationApplicationStatusHistories.length > 0
      ) {
        return this.selectedApplicationApplicationStatusHistories.reduce(
          (m, v, i) =>
            new Date(v.CreatedDate).getTime() >
              new Date(m.CreatedDate).getTime() && i
              ? v
              : m
        )
      }
      return null
    },
    availableStatuses() {
      let statuses = this.luApplicationStatuses.filter((x) => x.IsActive)
      if (this.currentStatus && this.currentStatus.StatusId > 0) {
        //we have a current status and need to find the statuses available as the next status
        let match = statuses.find(
          (x) => x.Id == this.currentStatus.StatusId
        )?.NextAvailableStatuses
        // filter the list down to ones available to one of the current user roles
        if (match) {
          let availableStatuses = match
            .filter((x) =>
              x.Roles.some(
                (a) =>
                  this.currentUser.Roles.findIndex((r) => r.Id == a.Id) > -1
              )
            )
            //and map the next status associated with the record
            .map((i) => {
              return i.NextStatus
            })
          return availableStatuses
        }
      } else {
        //a status has not been entered, so the only available one is the in progress (DAT) status
        return statuses.filter((x) => x.Id == 1)
      }
    },
  },
  methods: {
    ...call('application', [
      'loadSelectedApplicationApplicationStatusHistories',
      'loadSelectedApplicationApplicationDocuments',
      'refreshSelectedApplication',
      'saveApplication',
    ]),
    loadLuApplicationStatuses: call(
      'luApplicationStatus/loadLuApplicationStatuses'
    ),
    loadLuReasons: call('luReason/loadLuReasons'),
    ...call('applicationStatusHistory', ['saveApplicationStatusHistory']),
    loadLuIncomeThresholds: call('luIncomeThreshold/loadLuIncomeThresholds'),
    refreshStatusHistoriesAndDocuments() {
      this.loadSelectedApplicationApplicationStatusHistories()
      this.loadSelectedApplicationApplicationDocuments()
    },
    async confirmNextStatus() {
      var nextStatus = this.validationStatus
      let app = new Application(this.application)
      if (nextStatus.StatusId == 2) {
        app.RefundAmount =
          Math.round(Number.parseFloat(this.calculatedRefundAmount) * 100) / 100
        await this.saveApplication(app.root())
      }
      let status = await this.saveApplicationStatusHistory(
        nextStatus.removeRelated()
      )
      this.loadSelectedApplicationApplicationStatusHistories()
      this.refreshSelectedApplication()
    },
  },
  watch: {},
}
</script>
<style scoped>
.item-label {
  font-weight: bold;
}

.item-value {
  font-weight: normal;
}

.item-subtext {
  font-size: smaller;
  font-weight: normal;
}
</style>
