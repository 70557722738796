<template
  >
    <div>
      <v-sheet outlined class="mt-3">
        <v-card class="mx-auto" height="100%" width="256">
          <v-navigation-drawer class="accent lighten-1" dark permanent>
            <template v-slot:prepend>
              <v-list-item class="primary--text text-center">
                <v-list-item-content class="primary--text ">
                  <v-list-item-title>Application Validations</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider color="teal"></v-divider>
            </template>

            <v-list dense>
              <v-list-item dense v-for="item in items" :key="item.title" class="primary--text">
                <v-list-item-action dense class="ma-1">
                  <!-- <v-list-item-action-text>action text</v-list-item-action-text> -->
                  <v-btn small icon :color="item.color">
                    <!-- <v-icon color="grey lighten-1">mdi-information</v-icon> -->
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-btn>
                </v-list-item-action>
                <!-- <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon> -->

                <v-list-item-content class="primary--text">
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                  <!-- <v-list-item-subtitle>subtitile</v-list-item-subtitle> -->
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-navigation-drawer>
        </v-card>
      </v-sheet>
      <br />
    </div>
</template>
<script>
import { get, sync, commit, call } from 'vuex-pathify'
export default {
  //model: {
  //	prop: ''
  //},
  props: {
    application: {
      type: Object,
    },
  },
  components: {},
  data () {
    return {}
  },
  computed: {
    taxBillExceedsIncomePercentage () {
      if (
        this.application.TotalHouseholdIncomeReported != null &&
        this.application.TaxBillAmount != null &&
        this.application.PropertyTaxGreaterThanIncomePercentage == true
      ) {
        var percentage = this.application.TotalHouseholdIncomeReported * .1
        if (this.application.TaxBillAmount > percentage) {
          return true
        } else {
          return false
        }
      } else {
        return null
      }
    },
    // ...get('luPeriod', ['currentLuPeriod']),
    items () {
      //todo: add validations
      //postmark deadline
      //payment1 deadline
      //payment2 deadline
      // income maximum
      //business deduction

      return [
        {
          title: 'Entered SSN',
          icon: this.application?.TaxableProperty?.Contact?.Ssn
            ? 'mdi-check-circle'
            : 'mdi-close-circle',
          color: this.application?.TaxableProperty?.Contact?.Ssn
            ? 'green'
            : 'red',
        },
        {
          title: 'Entered Parcel Number',
          icon: this.application?.TaxableProperty?.ParcelId
            ? 'mdi-check-circle'
            : 'mdi-close-circle',
          color: this.application?.TaxableProperty?.ParcelId ? 'green' : 'red',
        },
        {
          title: 'Primary Residence',
          icon: this.application.IsPrimaryResidence
            ? 'mdi-check-circle'
            : 'mdi-close-circle',
          color: this.application.IsPrimaryResidence ? 'green' : 'red',
        },
        {
          title: 'Household Details',
          icon2: 'mdi-minus-circle',
          icon: this.application.AreHouseholdDetailsProvided
            ? 'mdi-check-circle'
            : 'mdi-close-circle',
          color: this.application.AreHouseholdDetailsProvided ? 'green' : 'red',
        },
        {
          title: 'Residential Requirements',
          icon2: 'mdi-information',
          icon: this.application.DoResidentialRequirementsQualify
            ? 'mdi-check-circle'
            : 'mdi-close-circle',
          color: this.application.DoResidentialRequirementsQualify
            ? 'green'
            : 'red',
        },
        {
          title: 'Household Assets',
          icon: this.application.DoHouseholdAssetsQualify
            ? 'mdi-check-circle'
            : this.taxBillExceedsIncomePercentage ? 'mdi-minus-circle' : 'mdi-close-circle',
          color: this.application.DoHouseholdAssetsQualify ? 'green' : this.taxBillExceedsIncomePercentage ? 'grey' : 'red',
        },
        {
          title: 'Tax Exceed 10% Income',
          icon: this.taxBillExceedsIncomePercentage == null ? 'mdi-minus-circle' : this.taxBillExceedsIncomePercentage
            ? 'mdi-check-circle'
            : 'mdi-close-circle',
          color: this.taxBillExceedsIncomePercentage == null ? 'grey' : this.taxBillExceedsIncomePercentage ? 'green' : 'red',
        },
        // {
        //   title: 'Tax Exceed 10% Income',
        //   icon: this.application.PropertyTaxGreaterThanIncomePercentage
        //     ? 'mdi-check-circle'
        //     : 'mdi-close-circle',
        //   color: this.application.PropertyTaxGreaterThanIncomePercentage ? 'green' : 'red',
        // },
        {
          title: 'Tax Bill Attached',
          icon2: 'mdi-alert-circle',
          icon: this.application.IsTaxBillAttached
            ? 'mdi-check-circle'
            : 'mdi-close-circle',
          color: this.application.IsTaxBillAttached ? 'green' : 'red',
        },
        {
          title: 'Payment Receipt',
          icon: this.application.IsPaymentReceiptAttached
            ? 'mdi-check-circle'
            : 'mdi-close-circle',
          color: this.application.IsPaymentReceiptAttached ? 'green' : 'red',
        },
        {
          title: 'IRS Attached',
          icon: this.application.IsIrsAttached
            ? 'mdi-check-circle'
            : 'mdi-close-circle',
          color: this.application.IsIrsAttached ? 'green' : 'red',
        },
        {
          title: 'Application Signed',
          icon: this.application.IsApplicationSigned
            ? 'mdi-check-circle'
            : 'mdi-close-circle',
          color: this.application.IsApplicationSigned ? 'green' : 'red',
        },
        {
          title: 'Certify SSN',
          icon: this.application.CertifySsn
            ? 'mdi-check-circle'
            : this.application.IsApplicationSigned
              ? 'mdi-minus-circle'
              : 'mdi-close-circle',
          color: this.application.CertifySsn
            ? 'green'
            : this.application.IsApplicationSigned
              ? 'grey'
              : 'red',
        },
        {
          title: 'Backup Withholding',
          icon: this.application.NoBackupWithholding
            ? 'mdi-check-circle'
            : this.application.IsApplicationSigned
              ? 'mdi-minus-circle'
              : 'mdi-alert-circle',
          color: this.application.NoBackupWithholding
            ? 'green'
            : this.application.IsApplicationSigned
              ? 'grey'
              : 'orange',
        },
        {
          title: 'US Resident',
          icon: this.application.UsResident
            ? 'mdi-check-circle'
            : this.application.IsApplicationSigned
              ? 'mdi-minus-circle'
              : 'mdi-close-circle',
          color: this.application.UsResident
            ? 'green'
            : this.application.IsApplicationSigned
              ? 'grey'
              : 'red',
        },
        {
          title: 'Correct And Complete',
          icon: this.application.CorrectAndComplete
            ? 'mdi-check-circle'
            : this.application.IsApplicationSigned
              ? 'mdi-minus-circle'
              : 'mdi-close-circle',
          color: this.application.CorrectAndComplete
            ? 'green'
            : this.application.IsApplicationSigned
              ? 'grey'
              : 'red',
        },
      ]
    },
  },
  methods: {},
  watch: {},
}
</script>
<style scoped></style>
